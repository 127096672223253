import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, PatternValidator, Validators } from "@angular/forms";
import { first, take, map, tap, scan, takeWhile } from "rxjs/operators";
import { observable, Observable, timer, interval } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService, CommonService, UserService } from "../../core/_services";

@Component({
  selector: 'app-two-factor-verification',
  templateUrl: './two-factor-verification.component.html',
  styleUrls: ['./two-factor-verification.component.css']
})
export class TwoFactorVerificationComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = "";
  hide = true;
  confhide = true;
  userListData: any;
  currentUserData: any;
  isphoneverified = false;
  resendotpDisable = false;
  counter$: Observable<any>;
  counter: any;
  count = 60;
  hidePhoneNumber: any;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public commonService: CommonService,
    private authenticationService: AuthenticationService,
    private userService: UserService,) {

  }

  ngOnInit() {
    debugger
    this.currentUserData = JSON.parse(localStorage.getItem("currentUser"));
    this.getUserList();
    this.setTimer()
    this.loginForm = this.formBuilder.group({
      password: ["", [Validators.required, Validators.maxLength(6), Validators.pattern("^[0-9]*$")]],
    });

    if (this.isphoneverified) {
      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/twoFactorVerification";
    } else {
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/active";
    }
  }
  public getUserList() {
    debugger;
    this.userService.getAllUserbyId(this.currentUserData.userid).subscribe((data: any) => {
      // this.userListData = data.result.find((item) => item.id == this.currentUserData.userid);
      this.userListData = data.result
      this.hidePhoneNumber = this.userListData.phone.replace(/.(?=.{4})/g, 'x');
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }


    //  // un-comment this   =======================================================

    this.userService.verifieyOTP(this.currentUserData.userid, this.loginForm.controls.password.value)
      .subscribe((data: any) => {
        if (data.statusCode == 200) {
          this.returnUrl =
            this.route.snapshot.queryParams["returnUrl"] || "/active";
          this.router.navigate([this.returnUrl]);
        } if (data.statusCode == 404) {
          this.toastr.error(data.message);
        }
      });

    // ==================================================================================

    //  //  comment this==============================================

  // this.router.navigate(['/active']);

  }

  resendOtp() {
    this.userService.resendOtp(this.currentUserData.userid).subscribe((data: any) => {
      if (data) {
        console.log(this.currentUserData);
        this.toastr.success(
          "OTP successfully send to " + this.currentUserData.username
        );
        this.resendotpDisable = false;
        this.setTimer();
      }
    });
  }

 
  redirectToLogin() {
    this.router.navigate(["/login"]);
  }
  setTimer() {
    debugger
    // this.resendotpDisable = false
    // this.counter$ = timer(0, 1000).pipe(
    //   take(this.count),
    //   map(() => --this.count)
    // );

    this.counter$ = timer(0, 1000).pipe(
      scan(acc => --acc, 60),
      takeWhile(x => x >= 0)
    )
    this.counter$.subscribe((x) => {
      if (x == 0) {
        this.resendotpDisable = true;
      }
    });
  }
}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}